import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const HalfPieChartPointer = styled.span<{ value: number }>`
  position: absolute;
  bottom: 50%;

  background: ${props => props.theme.colors.dark[4]};

  ${({ value }) => css`
    transform-origin: bottom center;
    left: 50%;
    transform: translateX(-50%) rotate(${value * 180 - 90}deg);
    width: 2px;
    height: calc(100% / 4);

    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 12px;
      height: 12px;
      background: inherit;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  `}
`;
